
import GenericMixin from '@/lib/mixins/GenericMixin';
import TasqFeedbackMixin from '@/lib/mixins/TasqFeedbackMixin';
import gptModule from '@/store/modules/gptModule';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { getComponent, sleep, getConfigEnv } from '@/utils/helpers';
import Multiselect from 'vue-multiselect';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import metaDataModule from '@/store/modules/metaDataModule';
import { Debounce } from 'vue-debounce-decorator';

@Component({
  components: {
    TasqWellStatus: () => getComponent('tasqs/TasqWellStatus'),
    GptCommentBox: () => getComponent('gpt/GptCommentBox'),
    TasqLastActionDetails: () => getComponent('gpt/TasqLastActionDetails'),
    TasqWellAction: () => getComponent('tasqs/TasqWellAction'),
    TasqWellActionFeedback: () =>
      getComponent('tasqs/TasqWellActionFeedbackV2'),
    TasqWellFinalStepFeedback: () =>
      getComponent('tasqs/TasqWellFinalStepFeedback'),
    TasqSetpointFeedback: () => getComponent('gpt/GptSetpointFeedback'),
    TasqPlungerFeedback: () => getComponent('gpt/GptPlungerFeedback'),
    TasqWellDetails: () => getComponent('tasqs/TasqWellDetails'),
    GptRecommendation: () => getComponent('gpt/GptRecommendation'),
    GptViewSimilar: () => getComponent('gpt/GptViewSimilar'),
    TodoList: () => getComponent('gpt/TodoList'),
    GptSidebarPopup: () => getComponent('gpt/GptSidebarPopup'),
    Multiselect,
  },
})
export default class TasqFeedbackV6 extends mixins(
  TasqFeedbackMixin,
  GenericMixin
) {
  DATA_ENTRY_TAB = 'Data entry';

  HISTORY_TAB = 'Details';

  activeTab = this.DATA_ENTRY_TAB;

  defermentValue: any = -1;

  isFeedbackViewLoading = false;

  showFormSelectionModal = false;

  defermentLabelingModal = false;

  lastActionKey = '';

  hoverGoodCatch = false;

  showViewSimilar = false;

  hoverWrongCatch = false;

  resizeLoading = false;

  resizeTrigger: any = null;

  collapseWellStatus = false;

  hideWellActionSection = false;

  waitingOnOptions: any = [];

  showToDoList = false;

  get operatorName() {
    return getConfigEnv('OPERATOR_LOWERCASED');
  }

  get showFeedbackMiniView() {
    return tasqFeedbackModule.showFeedbackMiniView;
  }

  setFeedbackMiniViewHover() {
    tasqFeedbackModule.setIsFeedbackMiniViewHovering(true);
  }

  setFeedbackMiniViewNotHover() {
    tasqFeedbackModule.setIsFeedbackMiniViewHovering(false);
  }

  toggleFeedbackMiniView() {
    tasqFeedbackModule.setShowFeedbackMiniView(!this.showFeedbackMiniView);
  }

  get isFeedbackMiniViewHovering() {
    return tasqFeedbackModule.isFeedbackMiniViewHovering;
  }

  get productionUnit() {
    const productionType = getConfigEnv('PRODUCTION_TYPE');
    if (this.operatorName === 'petronascanada') {
      return 'e3m3';
    }
    if (productionType === 'oil') {
      return 'bbl';
    }
    if (productionType === 'boe') {
      return 'BOE';
    }
    return 'mcf';
  }

  get showFooterSection() {
    return (
      this.feedbackStepNo !== 3 &&
      this.activeTab === this.DATA_ENTRY_TAB &&
      !(this.tasq && this.tasq.completed != null && this.tasq.completed)
    );
  }

  resizeWindow() {
    this.resizeLoading = true;
    this.$nextTick(() => {
      this.resizeLoading = false;
    });
  }

  toogleWellActions() {
    this.hideWellActionSection = true;
    this.$nextTick(() => {
      this.hideWellActionSection = false;
    });
  }

  retrainTasq() {
    if (this.tasq && !this.isBatchResponding) {
      this.defermentLabelingModal = true;
    }
  }

  async created() {
    this.isFeedbackViewLoading = true
    await sleep(1000)
    this.isFeedbackViewLoading = false
    try{
    // const { tab } = this.$route.query;


    if (this.tasq) {
      // if (
      //   this.tasq.formsResponse &&
      //   this.tasq.formsResponse.length &&
      //   this.tasq.isManuallyAdded
      // ) {
      //   tasqFeedbackModule.setSeletedForms(this.tasq.formsResponse);
      //   tasqFeedbackModule.setStepper(2);
      // }

      if (this.tasq.snoozed && !this.tasq.isSnoozedForToday) {
        if (
          new Date().getTime() <
            new Date(this.tasq.snoozed.WaitStartDate).getTime() ||
          new Date().getTime() <=
            new Date(this.tasq.snoozed.UntilDate).getTime()
        ) {
          this.collapseWellStatus = true;
        }
      }

      if (this.tasq.isGoodCatch) {
        await this.getGoodCatchCalulations();
      }
    }

    await this.setWaitingOnOptions();
    await this.getLastActionDropdownValues()
    }catch(err){
      console.log(err)
    }

  }

  get isBatchResponding() {
    return tasqsListModule.isBatchResponding;
  }

  get isSetpointTasq() {
    return this.tasq.engineerType === this.$getConst('TASQ_SETPOINT_TYPE');
  }

  async resetDefermentPage() {
    this.defermentLabelingModal = false;
    await sleep(0.1);
    this.defermentLabelingModal = true;
  }

  backHandler() {
    tasqFeedbackModule.setStepper(1);
  }

  openFormDetails(formID) {
    if (this.feedbackStepNo === 1) {
      tasqFeedbackModule.setSelectedActionInputTab(formID);
      tasqFeedbackModule.setStepper(2);
    }
  }

  closeLastActionModal() {
    gptModule.setLastActionsInActive();
    this.showLastActionModal = false;
  }

  findSubstringInString(arr, target) {
    for (let subStr of arr) {
      if (target.includes(subStr)) {
        return subStr;
      }
    }
    return null;
  }

  async saveWellStatus(payload) {
    this.isSavingData = true;
    const matchedValue = this.findSubstringInString(
      this.waitingOnOptions,
      payload.body
    );
    if (!matchedValue) {
      return;
    }
    await sleep(100);
    try {
      const action: any = {
        id: this.tasq.engineerType !== 'Producing' ? this.tasq.id : '',
        wellName: this.tasq.wellName,
        comment: payload.reason || '',
        startDate: payload.date,
        reason: matchedValue,
        endDate: payload.end_date,
        level: this.tasq.level,
      };


      tasqsListModule.setIsLoadingTasqs(true);
      this.$nextTick(async () => {
        tasqsListModule.setIsLoadingTasqs(false);
      });

      await sleep(100);
      await tasqActionsModule.mutateWellStatus(action);
      await sleep(100);
      // if (this.tasq.engineerType !== 'Producing') {
      //   await tasqsListModule.getTasq({ PredictionID: this.tasq.id });
      // }

      this.$tasqAlert({
        title: 'Success',
        message: 'Update well status saved! ',
        type: 'success',
      });
    } catch (error) {
      this.$tasqAlert({
        title: 'Error',
        message: 'Update well status failed! ',
        type: 'error',
      });
      console.log(error);
    }

    this.isSavingData = false;
  }

  async setWaitingOnOptions() {
    if (!metaDataModule.requiredDatalist.length) {
      await metaDataModule.getDatalist();
    }

    const requiredDataList = metaDataModule.requiredDatalist;
    const formCategoriesItem = requiredDataList.find(
      (list) => list.title === 'Waiting On'
    );
    // const formArtificialLiftItem = requiredDataList.find((list) => list.title === 'Artificial Lift Types');
    if (formCategoriesItem) {
      const formCategoriesPayload = await metaDataModule.getDatalist(
        formCategoriesItem.id
      );
      if (
        formCategoriesPayload &&
        formCategoriesPayload.length &&
        formCategoriesPayload[0].ListItems
      ) {
        const waitingOnOptions = formCategoriesPayload[0].ListItems.filter(
          (field) => field.Active
        ).map((item) => item.Name);
        // waitingOnOptions =
        this.waitingOnOptions = waitingOnOptions.sort();
      }
    }
  }

  @Debounce(500)
  async nextHandler(event) {
    const payload = {
      ...event,
      query: '',
      stream: false,
      useAll: false,
    };

    let isOnlyGptForm = this.selectedForms.find((f) => f.isAiForm);
    let isFirstStep =
      !this.selectedForms.length ||
      (this.selectedForms.length === 1 && isOnlyGptForm);

    if (isFirstStep) {

      let postCommentPayload =  event;
        if (isOnlyGptForm) {
          postCommentPayload =  {
          ...event,
          form: this.formatSelectedForms(tasqFeedbackModule.selectedForms),
        };
        }
      if(event.start_date && event.end_date){

        const operatorDetails: any = this.operatorList.find((o) => o['appName'] === this.operatorName);
        const data: any = await gptModule.postWaitingOnComment(postCommentPayload);
        const waitingOnPayload = {
        operatorName: operatorDetails['operatorName'],
        nodeID: this.tasq.wellName,
      };
      await gptModule.getWaitingOnStatus(waitingOnPayload);
        this.saveWellStatus(data).then(() => {});
      }else{
        await gptModule.postGptComment(postCommentPayload);
      }


      await sleep(1000)
      await gptModule.getActionedTasqsCount({operatorName: this.operatorDetails['operatorName']})

      // await gptModule.getGptLastActions(payload);
      this.$tasqAlert({
        title: 'Success',
        message: 'Comment submitted successfully.',
        type: 'success',
      });

      tasqFeedbackModule.resetAllData();


      tasqFeedbackModule.setStepper(1);
      return;
    }
    if (this.feedbackStepNo === 1) {
      await this.handleFormResponse({ markAsComplete: false, isGPTView: true });
      this.gptCommentPayload = {
        ...event,
        form: this.formatSelectedForms(tasqFeedbackModule.selectedForms),
      };
      await gptModule.postGptComment(event);
    } else if (this.feedbackStepNo === 2) {
      console.log(this.gptCommentPayload);
      // @ts-ignore
      const isValidForm = await this.$refs.tasqWellActionFeedback.submitInput();
      if (isValidForm) {
        const payload = this.gptCommentPayload;

        await gptModule.postGptComment(payload);
        delete payload.form;
        payload.query = this.gptCommentPayload.body;
        payload.stream = false;
        payload.useAll = false;
        delete payload.body;
        await this.handleFormResponse({
          markAsComplete: true,
          isGPTView: true,
        });
        // await gptModule.getGptLastActions(payload);

        tasqFeedbackModule.setStepper(1);
        tasqFeedbackModule.resetAllData();
      }
      console.log('check seteping');
      console.log(this.feedbackStepNo);
      this.$tasqAlert({
        title: 'Success',
        message: 'Comment submitted successfully.',
        type: 'success',
      });
    }
  }

  gptCommentPayload: any = [];

  formatSelectedForms(formResponse) {
    return formResponse.map((form) => {
      const formReturnObj = { ...form };
      delete formReturnObj.updatedAt;
      delete formReturnObj.updatedBy;
      delete formReturnObj.operatorID;
      delete formReturnObj.lifeTimeCount;
      delete formReturnObj.lastThirtyDaysCount;
      delete formReturnObj.buildDetailsUrl;
      delete formReturnObj.createdAt;
      formReturnObj.formTime = new Date().toISOString();
      if (formReturnObj && formReturnObj.fields) {
        if (form.fields && form.dateOfDisplay && form.dateOfDisplay.id) {
          const dateOfDisplayField = form.fields.find(
            (field) => field.id === form.dateOfDisplay.id
          );
          if (dateOfDisplayField && dateOfDisplayField.localVal) {
            formReturnObj.formTime = new Date(
              dateOfDisplayField.localVal
            ).toISOString();
          }
        }

        // eslint-disable-next-line no-return-assign, no-param-reassign
        formReturnObj.fields = form.fields.map((field) => {
          const fieldReturnObj = field;
          delete fieldReturnObj.visibilityRules;
          delete fieldReturnObj.options;
          delete fieldReturnObj.description;
          delete fieldReturnObj.showDetails;
          delete fieldReturnObj.format;
          return fieldReturnObj;
        });
      } else {
        formReturnObj.fields = [];
      }
      // console.log(formReturnObj);
      return formReturnObj;
    });
  }

  async submitDeferment() {
    this.defermentLabelingModal = false;
    this.$tasqAlert({
      title: 'Success',
      message: 'Retrain successfully.',
      type: 'success',
    });
    this.getWellHistory();
  }

  showActionModal(event) {
    this.lastActionDetails = JSON.parse(JSON.stringify(event));
    this.showLastActionModal = true;
    gptModule.setLastActionsActive(event.date);
  }

  openSetpointRecModal(event) {
    this.lastActionDetails = event;
    this.showSetpointRecModal = true;
  }

  openPlungerRecModal(event) {
    this.lastActionDetails = event;
    this.showPlungerRecModal = true;
  }

  async removeTarget(message) {
    this.defermentLabelingModal = false;
    this.$tasqAlert({
      title: 'Success',
      message,
      type: 'success',
    });
    this.getWellHistory();
  }

  async goodCatchSubmit(isGoodCatch) {
    await tasqsListModule.updateTasqGoodCatch({
      predictionID: this.tasq.id,
      isGoodCatch,
    });
    await this.getGoodCatchCalulations();
  }

  async getGoodCatchCalulations() {
    const defermentValue = await tasqsListModule.getGoodCatchDefermentByTime();
    this.defermentValue = defermentValue ? Math.round(defermentValue) : 0;
  }
}
